import { createRouter, createWebHistory } from 'vue-router';
import NProgress from 'nprogress';
import store from '@/store';

const ViewPage = () =>
	import(
		/* webpackChunkName: "index-page" */ '@/views/ViewIndexPage.vue' //? Entry Point
	);

// const AdminPage = () =>
// 	import(/* webpackChunkName: "admin-page" */ "@/views/ViewAdminPage.vue");

// const AdminDashboard = () =>
// 	import(
// 		/* webpackChunkName: "admin-dashboard" */ "@/components/Admin/AdminDashboard.vue"
// 	);
const LoginPage = () => import(/* webpackChunkName: "admin-dashboard" */ '@/components/Admin/Auth/LoginPage.vue');
const RegisterPage = () => import(/* webpackChunkName: "admin-dashboard" */ '@/components/Admin/Auth/RegisterPage.vue');
const ForgotPassword = () =>
	import(/* webpackChunkName: "admin-dashboard" */ '@/components/Admin/Auth/ForgotPassword.vue');

// const BlogCategories = () =>
// 	import(
// 		/* webpackChunkName: "blog-categories" */ "@/components/Admin/Blog/BlogCategories.vue"
// 	);

// const BlogCreate = () =>
// 	import(
// 		/* webpackChunkName: "create-category" */ "@/components/Admin/Blog/BlogCreate.vue"
// 	);

// const BlogEdit = () =>
// 	import(
// 		/* webpackChunkName: "Edit-blog" */ "@/components/Admin/Blog/BlogEdit.vue"
// 	);

const HomePage = () => import(/* webpackChunkName: "home-page" */ '@/views/HomePage.vue');

const AboutPage = () => import(/* webpackChunkName: "about-page" */ '@/views/AboutPage.vue');

const PricingPage = () => import(/* webpackChunkName: "pricing-page" */ '@/views/PricingPage.vue');

const NotaryPage = () => import(/* webpackChunkName: "pricing-page" */ '@/views/NotaryPage.vue');

const SolutionPage = () => import(/* webpackChunkName: "solution-page" */ '@/views/SolutionPage.vue');

const ContactPage = () => import(/* webpackChunkName: "faq" */ '@/views/ContactPage.vue');

const ToSignPage = () => import(/* webpackChunkName: "Tosign landing page" */ '@/views/ToSignPage.vue');

const ToNotarisePage = () => import(/* webpackChunkName: "Tosign landing page" */ '@/views/ToNotarisePage.vue');

const ToVerifyPage = () => import(/* webpackChunkName: "Tosign landing page" */ '@/views/ToVerifyPage.vue');

const UploadDocument = () => import(/* webpackChunkName: "Tosign landing page" */ '@/views/UploadDocument.vue');

const SolutionToSign = () => import(/* webpackChunkName: "to-sign" */ '@/components/Solution/SolutionToSign.vue');

const SolutionToVerify = () => import(/* webpackChunkName: "to-verify" */ '@/components/Solution/SolutionToVerify.vue');

const SolutionToNotarise = () =>
	import(/* webpackChunkName: "to-notarise" */ '@/components/Solution/SolutionToNotarise.vue');

const ResourcesPage = () => import(/* webpackChunkName: "resource-page" */ '@/views/ResourcesPage.vue');

const ResourceBlog = () => import(/* webpackChunkName: "blog" */ '@/components/Resources/ResourceBlog.vue');

const ResourceFAQ = () => import(/* webpackChunkName: "faq" */ '@/components/Resources/ResourceFAQ.vue');

const ResourcePartners = () => import(/* webpackChunkName: "faq" */ '@/components/Resources/ResourcePartners.vue');

const PrivacyPolicy = () => import(/* webpackChunkName: "about-page" */ '@/views/PrivacyPolicy.vue');

const TermsPage = () => import(/* webpackChunkName: "about-page" */ '@/views/TermsPage.vue');

const routes = [
	// {
	// 	path: "/admin",
	// 	name: "Admin",
	// 	component: AdminPage,
	// 	meta: {
	// 		title: "Admin | ToNote",
	// 	},
	// 	children: [
	// 		{
	// 			path: "",
	// 			name: "admin.dashboard",
	// 			component: AdminDashboard,
	// 			meta: {
	// 				title: "Admin | ToNote",
	// 				transitionName: "slide",
	// 				metaTags: [
	// 					{
	// 						name: "description",
	// 						content: "The blog page of ToNote.",
	// 					},
	// 					{
	// 						property: "og:description",
	// 						content: "The blog page of ToNote.",
	// 					},
	// 				],
	// 			},
	// 		},

	// 		{
	// 			path: "category",
	// 			name: "admin.category",
	// 			component: BlogCategories,
	// 			meta: {
	// 				title: "Category | ToNote",
	// 				// requiresAuth: true,
	// 				metaTags: [
	// 					{
	// 						name: "description",
	// 						content: "The category page of ToNote.",
	// 					},
	// 					{
	// 						property: "og:description",
	// 						content: "The category page of ToNote.",
	// 					},
	// 				],
	// 			},
	// 		},
	// 		{
	// 			path: "create",
	// 			name: "admin.create",
	// 			component: BlogCreate,
	// 			meta: {
	// 				title: "Create Category | ToNote",
	// 			},
	// 		},
	// 		{
	// 			path: "edit/:blog_id",
	// 			name: "admin.edit",
	// 			component: BlogEdit,
	// 			meta: {
	// 				title: "Edit Blog | ToNote",
	// 				metaTags: [
	// 					{
	// 						name: "description",
	// 						content: "The Blog edit page of ToNote.",
	// 					},
	// 					{
	// 						property: "og:description",
	// 						content: "The Blog edit page of ToNote.",
	// 					},
	// 				],
	// 			},
	// 		},
	// 	],
	// },

	{
		path: '/login',
		name: 'Login',
		component: LoginPage,
		meta: {
			title: 'Login | ToNote',
			transitionName: 'slide',
			metaTags: [
				{
					name: 'description',
					content: 'The blog page of ToNote.',
				},
				{
					property: 'og:description',
					content: 'The blog page of ToNote.',
				},
			],
		},
	},
	{
		path: '/register',
		name: 'Register',
		component: RegisterPage,
		meta: {
			title: 'Register | ToNote',
			transitionName: 'slide',
			metaTags: [
				{
					name: 'description',
					content: 'The blog page of ToNote.',
				},
				{
					property: 'og:description',
					content: 'The blog page of ToNote.',
				},
			],
		},
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		component: ForgotPassword,
		meta: {
			title: 'ForgotPassword | ToNote',
			transitionName: 'slide',
			metaTags: [
				{
					name: 'description',
					content: 'The blog page of ToNote.',
				},
				{
					property: 'og:description',
					content: 'The blog page of ToNote.',
				},
			],
		},
	},

	{
		path: '/',
		name: 'Index',
		meta: {
			title: 'Welcome',
			transitionName: 'fade',
			metaTags: {
				name: 'Description',
				content:
					'Build trust in your applications, registrations, and transactions. Get Affidavits Online. Notarize Documents with ease and Verify the authenticity of your documents from anywhere in the world.',
			},
		},

		component: ViewPage,
		children: [
			{
				path: '',
				name: 'Home',
				component: HomePage,
				meta: {
					title: "ToNote: Africa's electronic signature solution",
					transitionName: 'fade',
					metaTags: [
						{
							name: 'Description',
							content: "ToNote is Africa's foremost electronic signature and online notary solution.",
						},
						{
							property: 'og:Description',
							content: "ToNote is Africa's foremost electronic signature and online notary solution.",
						},
					],
				},
			},
			{
				path: '/',
				name: 'Sign in',
				// component: AboutPage,
				meta: {
					title: 'Sign in',
					transitionName: 'fade',
					metaTags: [
						{
							name: 'Sign in to access your ToNote account',
							content: 'Sign in to access your ToNote account',
						},
						{
							property: 'og:Sign in to access your ToNote account',
							content: 'Sign in to access your ToNote account.',
						},
					],
				},
			},
			{
				path: '/about',
				name: 'About',
				component: AboutPage,
				meta: {
					title: 'About | ToNote',
					transitionName: 'fade',
					metaTags: [
						{
							name: 'description',
							content: 'The about page of ToNote.',
						},
						{
							property: 'og:description',
							content: 'The about page of ToNote.',
						},
					],
				},
			},
			{
				path: '/solution',
				name: 'Solution',
				component: SolutionPage,

				meta: {
					title: 'Solutions - ToNote',
					transitionName: 'fade',
				},
				children: [
					{
						path: 'tosign',
						name: 'solution.sign',
						component: SolutionToSign,
						meta: {
							title: 'Sign | ToNote',
							transitionName: 'fade',
							metaTags: [
								{
									name: 'description',
									content: 'To sign page of ToNote.',
								},
								{
									property: 'og:description',
									content: 'To sign page of ToNote.',
								},
							],
						},
					},
					{
						path: 'tonotarise',
						name: 'solution.notarise',
						component: SolutionToNotarise,
						meta: {
							title: 'Notarise | ToNote',
							transitionName: 'fade',
							metaTags: [
								{
									name: 'description',
									content: 'To notarise page of ToNote.',
								},
								{
									property: 'og:description',
									content: 'To notarise page of ToNote.',
								},
							],
						},
					},
					{
						path: 'toverify',
						name: 'solution.verify',
						component: SolutionToVerify,
						meta: {
							title: 'Verify | ToNote',
							transitionName: 'fade',
							metaTags: [
								{
									name: 'description',
									content: 'To verify page of ToNote.',
								},
								{
									property: 'og:description',
									content: 'To verify page of ToNote.',
								},
							],
						},
					},
				],
			},
			{
				path: '/contact',
				name: 'contact',
				component: ContactPage,
				meta: {
					title: 'Contact | ToNote',
					transitionName: 'fade',
					metaTags: [
						{
							name: 'description',
							content: 'The contact page of ToNote.',
						},
						{
							property: 'og:description',
							content: 'The contact page of ToNote.',
						},
					],
				},
			},
			{
				path: '/pricing',
				name: 'Pricing',
				component: PricingPage,
				meta: {
					title: 'Pricing | ToNote',
					transitionName: 'fade',
					metaTags: [
						{
							name: 'description',
							content: 'The pricing page of ToNote.',
						},
						{
							property: 'og:description',
							content: 'The pricing page of ToNote.',
						},
					],
				},
			},
			{
				path: '/notary',
				name: 'Notary',
				component: NotaryPage,
				meta: {
					title: 'Notary | ToNote',
					transitionName: 'fade',
					metaTags: [
						{
							name: 'description',
							content: 'The notary page of ToNote.',
						},
						{
							property: 'og:description',
							content: 'The notary page of ToNote.',
						},
					],
				},
			},
			{
				path: '/resources',
				name: 'Resources',
				component: ResourcesPage,
				meta: {
					title: 'Resources - ToNote',
					transitionName: 'fade',
				},
				children: [
					{
						path: '',
						name: 'resource.blog',
						component: ResourceBlog,
						meta: {
							title: 'Blog | ToNote',
							transitionName: 'fade',
							metaTags: [
								{
									name: 'description',
									content: 'The blog page of ToNote.',
								},
								{
									property: 'og:description',
									content: 'The blog page of ToNote.',
								},
							],
						},
					},
					{
						path: 'faq',
						name: 'resource.faq',
						component: ResourceFAQ,
						meta: {
							title: 'FAQ | ToNote',
							transitionName: 'fade',
							metaTags: [
								{
									name: 'description',
									content: 'The FAQ page of ToNote.',
								},
								{
									property: 'og:description',
									content: 'The FAQ page of ToNote.',
								},
							],
						},
					},
					{
						path: 'partners',
						name: 'resource.partners',
						component: ResourcePartners,
						meta: {
							title: 'Partners | ToNote',
							transitionName: 'fade',
							metaTags: [
								{
									name: 'description',
									content: 'The Partners page of ToNote.',
								},
								{
									property: 'og:description',
									content: 'The Partners page of ToNote.',
								},
							],
						},
					},
				],
			},
			{
				path: '/privacy',
				name: 'Privacy',
				component: PrivacyPolicy,
				meta: {
					title: 'Privacy | ToNote',
					transitionName: 'fade',
					metaTags: [
						{
							name: 'description',
							content: 'The privacy page of ToNote.',
						},
						{
							property: 'og:description',
							content: 'The privacy page of ToNote.',
						},
					],
				},
			},
			{
				path: '/terms',
				name: 'Terms',
				component: TermsPage,
				meta: {
					title: 'Terms | ToNote',
					transitionName: 'fade',
					metaTags: [
						{
							name: 'description',
							content: 'The terms page of ToNote.',
						},
						{
							property: 'og:description',
							content: 'The terms page of ToNote.',
						},
					],
				},
			},
			{
				path: '/tosign',
				name: 'ToSignPage',
				component: ToSignPage,
				meta: {
					title: 'ToSign | E-Signing',
					transitionName: 'fade',
					metaTags: [
						{
							name: 'description',
							content: 'The E-Signing page of ToNote.',
						},
						{
							property: 'og:description',
							content: 'The E-Signing page of ToNote.',
						},
					],
				},
			},
			{
				path: '/tonotarise',
				name: 'ToNotarisePage',
				component: ToNotarisePage,
				meta: {
					title: 'ToSign | E-Notary',
					transitionName: 'fade',
					metaTags: [
						{
							name: 'description',
							content: 'The  E-Notary page of ToNote.',
						},
						{
							property: 'og:description',
							content: 'The  E-Notary page of ToNote.',
						},
					],
				},
			},
			{
				path: '/upload',
				name: 'UploadDocument',
				component: UploadDocument,
				meta: {
					title: 'UploadDocument | Document UploadDocument',
					transitionName: 'fade',
					metaTags: [
						{
							name: 'description',
							content: 'The UploadDocument page of ToNote.',
						},
						{
							property: 'og:description',
							content: 'The UploadDocument page of ToNote.',
						},
					],
				},
			},
			{
				path: '/toverify',
				name: 'ToVerifyPage',
				component: ToVerifyPage,
				meta: {
					title: 'ToNote | Document verification',
					transitionName: 'fade',
					metaTags: [
						{
							name: 'description',
							content: 'The verification page of ToNote.',
						},
						{
							property: 'og:description',
							content: 'The verification page of ToNote.',
						},
					],
				},
			},
		],
	},

	{
		path: '/:catchAll(.*)',
		component: () => import('@/components/NotFound.vue'),
		meta: {
			title: 'Error 404 | ToNote',
			transitionName: 'zoom',
			metaTags: [
				{
					name: 'description',
					content: 'The error 404 page of ToNote.',
				},
				{
					property: 'og:description',
					content: 'The error 404 page of ToNote.',
				},
			],
		},
	},
];

// const router = createRouter({
//   history: createWebHistory(),
//   routes,
//   linkActiveClass: "active",
//   scrollBehavior(to, from, savedPosition) {
//     return { left: 0, top: 0 };
//   },
// });

const router = createRouter({
	history: createWebHistory(),
	routes,
	linkActiveClass: 'active',
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return {
				savedPosition,
				behavior: 'smooth',
			};
		} else {
			return { x: 0, y: 0 };
		}
	},
});

router.beforeEach((to, from, next) => {
	NProgress.start();

	/* ---------------------------------------------//? ROUTE GUARD META -------------------------------------------- */

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		const hasToken = store.getters['auth/token'];
		if (!hasToken) {
			next({ name: 'Home' });
		} else {
			next();
		}
	} else {
		next();
	}

	/* ---------------------------------------------//? SEO Friendly META -------------------------------------------- */

	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);
	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	// if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	} else {
		document.title = previousNearestWithMeta.meta.title;
	}

	// Set canonical URL dynamically
	const baseUrl = 'https://www.gettonote.com';
	const canonicalUrl = `${baseUrl}${to.path}`;

	let link = document.querySelector("link[rel='canonical']");
	if (link) {
		link.setAttribute('href', canonicalUrl);
	} else {
		link = document.createElement('link');
		link.setAttribute('rel', 'canonical');
		link.setAttribute('href', canonicalUrl);
		document.head.appendChild(link);
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement('meta');

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create, so we don't interfere with other ones.
			tag.setAttribute('data-vue-router-controlled', '');

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	next();
});

router.afterEach(() => {
	NProgress.done();
});

export default router;
